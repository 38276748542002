import { useLocation } from "react-router-dom";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { ProductType } from "../../../../globalTypes";
import { useMutation } from "@apollo/client";
import { CREATE_PRODUCT } from "../../graphql/CREATE_PRODUCT";
import {
  createProduct,
  createProductVariables,
} from "../../graphql/__generated__/createProduct";
import { UPDATE_PRODUCT } from "../../graphql/UPDATE_PRODUCT";
import {
  updateProduct,
  updateProductVariables,
} from "../../graphql/__generated__/updateProduct";
import globalDataCTX from "../../../../contexts/globalContext/globalDataCTX";
import { notificationEventType } from "../../../../contexts/globalContext/types";
import { Enum_product_inputs, locationStateType } from "./types";

export const useProductCreateUpdateForm = () => {
  const { setNotificationEvent, setNotificationMsg, setLoading } =
    useContext(globalDataCTX);

  const { state }: locationStateType = useLocation();
  const isUpdatePage = !!state?.productDataForUpdate;

  const [productIdForUpdate, setProductIdForUpdate] = useState<number>(0);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [order, setOrder] = useState(0);
  const [name, setName] = useState("");
  const [amount, setAmount] = useState(0);
  const [productType, setProductType] = useState(ProductType.service);

  useEffect(() => {
    if (state?.productDataForUpdate) {
      const { active, order, amount, id, name, productType } =
        state?.productDataForUpdate;

      setIsActive(active);
      setOrder(order);
      setName(name);
      setAmount(amount);
      setProductType(productType);
      setProductIdForUpdate(id);
    }
  }, [state?.productDataForUpdate]);

  const [
    createProductMutation,
    { data: createProductData, loading: createLoading, error: createError },
  ] = useMutation<createProduct, createProductVariables>(CREATE_PRODUCT);

  const [
    updateProductMutation,
    { data: updateProductData, loading: updateLoading, error: updateError },
  ] = useMutation<updateProduct, updateProductVariables>(UPDATE_PRODUCT);

  const onChangeInputsHandler = (
    event: ChangeEvent<HTMLInputElement> | any,
    input: Enum_product_inputs
  ) => {
    switch (input) {
      case Enum_product_inputs.status:
        setIsActive((prevState) => !prevState);
        break;
      case Enum_product_inputs.order:
        setOrder(event.target.value);
        break;
      case Enum_product_inputs.name:
        setName(event.target.value);
        break;
      case Enum_product_inputs.amount:
        setAmount(event.target.value);
        break;
      case Enum_product_inputs.productType:
        setProductType(event.target.value);
        break;
      default:
        break;
    }
  };

  const createUpdateHandler = () => {
    isUpdatePage
      ? updateProductMutation({
        variables: {
          input: {
            id: productIdForUpdate,
            active: isActive,
            amount,
            name,
            order,
            productType
          },
        },
      })
      : createProductMutation({
        variables: {
          input: {
            active: isActive,
            amount,
            image: "",
            name,
            order,
            productType,
          },
        },
      });
  };

  useEffect(() => {
    const isLoading = createLoading || updateLoading;
    setLoading(isLoading);

    if (createProductData && !createLoading && !createError) {
      setNotificationEvent(notificationEventType.success);
      setNotificationMsg("Успешно добавлено");
    } else if (updateProductData && !updateLoading && !updateError) {
      setNotificationEvent(notificationEventType.success);
      setNotificationMsg("Успешно обновлено");
    } else if (createError || updateError) {
      setNotificationEvent(notificationEventType.error);
      setNotificationMsg(createError?.message || updateError?.message || "");
    }
  }, [
    createError,
    createLoading,
    createProductData,
    setLoading,
    setNotificationEvent,
    setNotificationMsg,
    updateError,
    updateLoading,
    updateProductData,
  ]);

  return {
    isUpdatePage,
    isActive,
    order,
    name,
    amount,
    productType,
    isLoading: createLoading || updateLoading,
    createUpdateHandler,
    onChangeInputsHandler,
  };
};
