export const productsTabelNamesHeader = [
  {
    name: "ID",
  },
  {
    name: "Имя",
  },
  {
    name: "Цена",
  },
  {
    name: "Ордер",
  },
  {
    name: "Статус",
  },
  {
    name: "Тип продукта",
  },
  {
    name: "Копировать ID",
  },
];
