import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useMemo, useState } from "react";
import globalDataCTX from "../../contexts/globalContext/globalDataCTX";
import { GET_ALL_EXPERTS_ADMIN_DASHBOARD } from "./graphql/GET_ALL_EXPERTS_ADMIN_DASHBOARD.graphql";
import { getExpertsAdminDashboard, getExpertsAdminDashboard_getExpertsAdminDashboard_edges, getExpertsAdminDashboardVariables } from "./graphql/__generated__/getExpertsAdminDashboard";

export const useExportsTable = () => {
  const { setLoading, loading } = useContext(globalDataCTX);
  const [searchText, setSearchText] = useState<string>("");
  const [experts, setExperts] = useState<(getExpertsAdminDashboard_getExpertsAdminDashboard_edges | null)[]>([])

  const [getAllExperts, { data, loading: loadingExperts, error: expertsError }] = useLazyQuery<
  getExpertsAdminDashboard,
  getExpertsAdminDashboardVariables
  >(GET_ALL_EXPERTS_ADMIN_DASHBOARD);

  const searchExpertData = useMemo(() => {
    const searchExpertData = data?.getExpertsAdminDashboard?.edges.filter((item) => {
      return item?.node.name.toLowerCase().includes(searchText);
    });

    return searchExpertData;
  }, [data?.getExpertsAdminDashboard?.edges, searchText]);

  useEffect(() => {
    getAllExperts();
    setLoading(loadingExperts);
  }, [getAllExperts, loadingExperts, setLoading]);

  useEffect(() => {
    if (data && !loading && !expertsError) {
      const sortedExperts = [...(data.getExpertsAdminDashboard?.edges || [])].sort((a, b) => {
        const statusA = a?.node?.activityStatus || "";
        const statusB = b?.node?.activityStatus || "";
  
        if (statusA === "active" && statusB !== "active") {
          return -1;
        }
        if (statusA !== "active" && statusB === "active") {
          return 1;
        }
  
        if (statusA === "inactive" && statusB !== "inactive") {
          return -1;
        }
        if (statusA !== "inactive" && statusB === "inactive") {
          return 1;
        }
  
        return 0;
      });
  
      setExperts(sortedExperts);
    }
  }, [data, loading, expertsError]);
  
  

  return {
    allExpertData: searchText ? searchExpertData : experts,
    searchText,
    loading,
    setSearchText
  };
};
