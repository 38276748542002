import React from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  Skeleton,
  Button,
} from "@mui/material";
import CashBackTableHead from "../productsTableHead";
import { useNavigate } from "react-router-dom";
import { StyledTableCell } from "../../../../styles/common/styles";
import { StyledStatusColorByBoolean, StyledTableCellBody } from "./styles";
import { productsTabelNamesHeader } from "../productsTableHead/constants";
import { ProductsTableBodyProps } from "./types";
import { useCopyTextHandler } from "../../../../hooks/useCopyText/useCopyTextHandler";

const ProductTableBody = ({
  productsData,
  isLoading,
}: ProductsTableBodyProps) => {
  const navigate = useNavigate();
  const { copyTextHandler } = useCopyTextHandler();

  return (
    <TableContainer
      component={Paper}
      sx={{
        borderTopRightRadius: "16px",
        borderTopLeftRadius: "16px",
        mt: "24px",
      }}
    >
      <Table
        aria-label="customized table"
        size="medium"
        sx={{ borderCollapse: "inherit !important" }}
      >
        <CashBackTableHead isLoading={false} />
        <TableBody>
          {productsData?.map((product, idx) => {
            return (
              <TableRow
                key={idx}
                hover
                sx={{ cursor: "pointer" }}
                onClick={() =>
                  navigate("update", {
                    state: {
                      productDataForUpdate: product,
                    },
                  })
                }
              >
                {!isLoading ? (
                  <>
                    <StyledTableCellBody
                      status={product?.active ? "active" : "inactive"}
                    >
                      {product?.id}
                    </StyledTableCellBody>
                    <StyledTableCell>{product?.name}</StyledTableCell>
                    <StyledTableCell>{product?.amount}</StyledTableCell>
                    <StyledTableCell>{product?.order}</StyledTableCell>
                    <StyledTableCell>
                      <StyledStatusColorByBoolean status={product?.active}>
                        {product?.active ? "Активный" : "Неактивный"}
                      </StyledStatusColorByBoolean>
                    </StyledTableCell>
                    <StyledTableCell>
                      {product?.productType || "----"}
                    </StyledTableCell>
                    <StyledTableCell>
                      {product.code && <Button
                        variant="outlined"
                        onClick={(event) => copyTextHandler(product.code, event)}
                      >
                        {product.code}
                      </Button>}
                    </StyledTableCell>
                  </>
                ) : (
                  productsTabelNamesHeader.map(() => {
                    return (
                      <StyledTableCell key={idx} align="left">
                        <Skeleton animation="wave" />
                      </StyledTableCell>
                    );
                  })
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductTableBody;
