import React, { FC } from "react";
import { PromoCodesItemProps } from "./types";
import { StylesContainerPromosCodes } from "./styles";
import { Box, Button, Typography } from "@mui/material";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import IconComponent from "../../../common/icon";
import { useNavigate } from "react-router-dom";
import { useCopyTextHandler } from "../../../../hooks/useCopyText/useCopyTextHandler";

const PromoCodesItem: FC<PromoCodesItemProps> = ({
  code,
  setPromocodeForAffise,
}) => {
  const navigate = useNavigate();
  const { copyTextHandler } = useCopyTextHandler();

  return (
    <StylesContainerPromosCodes>
      <Typography component={"span"}>Имя: {code}</Typography>
      <Box sx={{ display: "flex", gap: "5px" }}>
        <Button
          variant="contained"
          onClick={() => code && copyTextHandler(code)}
          startIcon={<IconComponent icon={faCopy} />}
        >
          Копировать промо-код
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            if (setPromocodeForAffise && code) {
              setPromocodeForAffise(code);
            }

            navigate("join-promo-to-effise", { state: { code } });
          }}
        >
          Привязать промокод к Affise
        </Button>
      </Box>
    </StylesContainerPromosCodes>
  );
};

export default PromoCodesItem;
