import { useQuery } from "@apollo/client";
import { GET_ALL_PRODUCTS } from "./graphql/GET_PRODUCTS";
import { getProductsByAdmin, getProductsByAdminVariables } from "./graphql/__generated__/getProductsByAdmin";
import { OrderEnum, SortByEnum } from "../../globalTypes";

export const useProduct = () => {
  const { data, loading } = useQuery<getProductsByAdmin, getProductsByAdminVariables>(
    GET_ALL_PRODUCTS, {
    variables: {
      sorting: { order: OrderEnum.desc, sortBy:  SortByEnum.date},
      limit: 0
    },
    fetchPolicy: "network-only"
  });

  return {
    products: data?.getProductsByAdmin.products || [],
    loading,
  };
};
