import { gql } from "@apollo/client";

export const GET_ALL_PRODUCTS = gql`
 query getProductsByAdmin($limit: Int,$page: Int, $sorting:SortParameters){
    getProductsByAdmin(limit: $limit, page: $page, sorting: $sorting){
      products{
        id
        order
        amount
        name
        image
        active
        productType
        code
      }
    }
  } 
`;
