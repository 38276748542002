import React from "react";
import { Box } from "@mui/material";
import { HeaderTitle } from "../../styles/common/styles";
import { useProduct } from "./useProducts";
import ProductTableBody from "./components/productsTableBody";
import SearchBar from "./components/searchBar";

const Products = () => {
  const { products, loading } = useProduct();
  return (
    <Box>
      <HeaderTitle>Продукты</HeaderTitle>
      <SearchBar />
      <ProductTableBody productsData={products} isLoading={loading} />
    </Box>
  );
};

export default Products;
