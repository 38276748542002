import { useContext } from "react";
import globalDataCTX from "../../contexts/globalContext/globalDataCTX";
import { notificationEventType } from "../../contexts/globalContext/types";

export const useCopyTextHandler = () => {
    const { setNotificationEvent, setNotificationMsg } = useContext(globalDataCTX);
  
    const copyTextHandler = async (
        text: string,
        event?: React.MouseEvent<HTMLElement>
    ) => {
      if (event) event.stopPropagation();
      try {
        await navigator.clipboard.writeText(text);
        setNotificationEvent(notificationEventType.success);
        setNotificationMsg("Скопирован");
      } catch (error) {
        setNotificationEvent(notificationEventType.error);
        setNotificationMsg(`Не удалось скопировать: ${error}`);
      }
    };
  
    return { copyTextHandler };
  };
  
