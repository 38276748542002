import React from "react";
import { Box } from "@mui/material";
import BackButton from "../../common/BackButton";
import ProductCreateUpdateForm from "../components/productCreateUpdateForm";

const CreateUpdateProduct = () => {
  return (
    <Box>
      <BackButton />
      <ProductCreateUpdateForm />
    </Box>
  );
};

export default CreateUpdateProduct;
