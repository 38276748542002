import React from "react";
import {  Button } from "@mui/material";
// import {
//   StyledToggleButtonGroup,
//   StyledToggleButton,
// } from "../../../reviews/filtersPanel/statusTabs/styles";
// import { SearchBarProps } from "./types";
// import statusFilterData from "./constants";
import { StyledSearchBarWrapper } from "../../../../styles/common/styles";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

const SearchBar = () => {
  const navigate = useNavigate();
  // const [filterIsActive, setFilterIsActive] = useState<boolean | null>(null);
  // const [filterTabNumber, setFilterTabNumber] = useState<number>(3);

  // const onChange = (event: any, newValue: number) => {
  //   setFilterTabNumber(newValue);

  //   switch (newValue) {
  //     case 1:
  //       setFilterIsActive(true);
  //       break;
  //     case 2:
  //       setFilterIsActive(false);
  //       break;

  //     default:
  //       setFilterIsActive(null);
  //   }
  // };

  // useEffect(() => {
  //   refetchProductMutation({ filter: { active: filterIsActive } })
  // }, [filterIsActive, refetchProductMutation])

  return (
    <StyledSearchBarWrapper direction="row">
      {/* <Box display={"flex"} gap={2}>
        <Box>
          <StyledToggleButtonGroup
            value={filterTabNumber || 3}
            exclusive
            onChange={onChange}
            aria-label="text alignment"
          >
            {statusFilterData.map((item) => (
              <StyledToggleButton key={item.title} value={item.value || 0}>
                {item.title}
              </StyledToggleButton>
            ))}
          </StyledToggleButtonGroup>
        </Box>
      </Box> */}
      <Button
        variant="contained"
        color="info"
        onClick={() => navigate("create")}
      >
        <AddIcon sx={{ mr: 1 }} />
        Добавить новый продукт
      </Button>
    </StyledSearchBarWrapper>
  );
};

export default SearchBar;
