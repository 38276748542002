import { getProductsByAdmin_getProductsByAdmin_products } from "../../graphql/__generated__/getProductsByAdmin";

export interface locationStateType {
  state: {
    productDataForUpdate: getProductsByAdmin_getProductsByAdmin_products | null;
  };
}

export enum Enum_product_inputs {
  status = "status",
  order = "order",
  amount = "amount",
  name = "name",
  productType = "productType",
}
