import React from "react";
import { useProductCreateUpdateForm } from "./useProductCreateUpdateForm";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { HeaderTitle } from "../../../schedule/allSchedules/styles";
import { MenuProps } from "../../../cashBack/components/createUpdateCashBackForm/constats";
import { ProductType } from "../../../../globalTypes";
import { Enum_product_inputs } from "./types";

const ProductCreateUpdateForm = () => {
  const {
    isUpdatePage,
    createUpdateHandler,
    productType,
    name,
    amount,
    isActive,
    onChangeInputsHandler,
    order,
  } = useProductCreateUpdateForm();
  return (
    <Box>
      <Divider textAlign="right">
        <HeaderTitle>
          {isUpdatePage ? "Обновить продукт" : "Добавить продукт"}
        </HeaderTitle>
      </Divider>

      <Box display={"flex"} width={"100%"}>
        <Card sx={{ minWidth: "50%" }}>
          <CardActionArea>
            <CardContent
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              <TextField
                value={name}
                label="Имя"
                variant="outlined"
                onChange={(event) =>
                  onChangeInputsHandler(event, Enum_product_inputs.name)
                }
              />
              <TextField
                value={amount}
                label="Сумма"
                variant="outlined"
                onChange={(event) =>
                  onChangeInputsHandler(event, Enum_product_inputs.amount)
                }
              />

              <TextField
                value={order}
                label="порядок"
                variant="outlined"
                onChange={(event) =>
                  onChangeInputsHandler(event, Enum_product_inputs.order)
                }
              />

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Тип продукта
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Тип продукта"
                  value={productType}
                  onChange={(event) =>
                    onChangeInputsHandler(
                      event,
                      Enum_product_inputs.productType
                    )
                  }
                  MenuProps={MenuProps}
                >
                  <MenuItem value={ProductType.gift}>
                    {ProductType.gift}
                  </MenuItem>
                  <MenuItem value={ProductType.service}>
                    {ProductType.service}
                  </MenuItem>
                </Select>
              </FormControl>

              {isUpdatePage && (
                <Box display={"flex"} alignItems={"center"}>
                  <Typography>Активно</Typography>
                  <Checkbox
                    checked={isActive}
                    onChange={(event) =>
                      onChangeInputsHandler(event, Enum_product_inputs.status)
                    }
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Box>
              )}
            </CardContent>
          </CardActionArea>
          <CardActions sx={{ float: "right", marginRight: 1 }}>
            <Button
              size="medium"
              variant="contained"
              onClick={createUpdateHandler}
              disabled={false}
            >
              {`${isUpdatePage ? "Обновить" : "Добавить"}`}
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Box>
  );
};

export default ProductCreateUpdateForm;
