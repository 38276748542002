import React from "react";
import { TableHead, TableRow, Skeleton } from "@mui/material";
import { productsTabelNamesHeader } from "./constants";
import { StyledTableCell } from "../../../../styles/common/styles";
import { ProductsTableHeaderProps } from "./types";

const CashBackTableHeader = ({ isLoading }: ProductsTableHeaderProps) => {
  return (
    <TableHead>
      <TableRow>
        {productsTabelNamesHeader.map(({ name }, idx) => {
          return !isLoading ? (
            <StyledTableCell key={idx} align="left">
              {name}
            </StyledTableCell>
          ) : (
            <StyledTableCell key={idx} align="left">
              <Skeleton animation="wave" />
            </StyledTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default CashBackTableHeader;
