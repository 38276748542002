import { useContext } from "react";
import { Navigate } from "react-router-dom";
import {
  removeAccessToken,
  removeRefreshToken,
} from "../../../../../sevices/securityService/securityServiceHelper";
import { UserCTX } from "../../../../../contexts/userContext/UserCTX";
import { client } from "../../../../../sevices/client/client";

export const useNavSection = () => {
  const { isUserLoggedIn } = useContext(UserCTX);

  const logOutHandler = () => {
    removeAccessToken();
    removeRefreshToken();
    const { cache } = client;
    cache.reset();
    return <Navigate to="/login" />;
  };

  return {
    logOutHandler,
    isUserLoggedIn,
  };
};
